<template>
  <div class="pingtai">
    <div class="top">
      <img src="../../common/image/组 27.png" alt="" />
    </div>
    <!-- 一种商业模式 -->
    <div class="moshi">
      <div class="aa">
        <img src="../../common/image/1.png" alt="" />
      </div>
      <div class="bb flexsb">
        <div class="left">
          <p>
            循循尚购是华麒商盟旗下的一个将本地生活服务与互联网消费行为相互结合的
            新共享综合运营平台。以运营平台+APP为载体，有效地将本地商家，线上供
            应链上游企业，消费用户，以跨空间，跨时间，多维度，无缝链接于一体循循
            尚购是华麒商盟旗下的一个将本地生活服务与互联网消费行为相互结合的新共
            享综合运营平台。以运营平台+APP为载体，有效地将本地商家，线上供应链
            上游企业，消费用户，以跨空间，跨时间，多维度，无缝链接于一体
          </p>
        </div>
        <div class="right">
          <img src="../../common/image/shangye.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 6大产品体系 -->
    <div class="chanpin">
      <div class="tixi">
        <img src="../../common/image/2.png" alt="" />
      </div>
      <div class="main flex">
        <el-tabs
          tabPosition="left"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            v-for="(i, k) in datalist"
            :key="k"
            :label="i.productName"
            :name="'index' + k"
          >
            <pingtaidetail :list="i" :key="k" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 5大积分体系 -->
    <div class="jifen" >
      <img src="../../common/image/3.png" alt="" />
    </div>
  </div>
</template>
<script>
import pingtaidetail from "./pingtaidetail";
export default {
  name: "pingtai",
  data() {
    return {
      activeName: "index0",
      index: 0, //动态标
      datalist: [], //产品体系
    };
  },
  created() {
    this.api.products().then((res) => {
      // console.log(res);
      this.datalist = res.data;
    });
  },
  wacth: {},
  components: { pingtaidetail },
  methods: {
    handleClick() {},
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.pingtai {
  .top {
    img {
      width: 100%;
    }
  }
}
.moshi {
  width: 1200px;
  margin: 0 auto;
  .aa {
    margin: auto;
    padding: 80px 0;
  }
  .bb {
    align-items: center;
    padding-bottom: 111px;
    .left {
      width: 544px;
      line-height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .right {
      width: 574px;
      height: 433px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.chanpin {
  // background: #fafafa;
  min-height: 619px;
  box-sizing: border-box;
  .tixi {
    margin: 0 auto;
    width: 1200px;
    padding: 71px 0;
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #ffffff;
  }
}
.jifen {
  margin-bottom: 70px;
  height: 975px;
  img {
    height: 100%;
    width: 100%;
  }
}
/deep/.el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: #fff !important;
}
/deep/.el-tabs__item.is-active {
  color: #981C1C;
  font-weight: bold;
}
/deep/.el-tabs__active-bar {
  // position: absolute;
  bottom: 0;
  left: 0 !important;
  height: 2px;
  background-color: #981c1c;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}
/deep/.el-tabs__nav-scroll {
  width: 267px;
  padding-top: 15px;
  margin-bottom: -15px;
  box-sizing: border-box;
}
/deep/.el-tabs__item:hover {
  color: #981c1c;
}
/deep/.el-tabs {
  width: 100%;
}
/deep/.el-tabs__nav,
.is-left {
  overflow-y: auto;
  height: 318px;
}
/deep/::-webkit-scrollbar {
  width: 1px !important;
}
</style>
